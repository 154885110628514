@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/orbitron');
@import url('https://fonts.cdnfonts.com/css/archivo');

@font-face {
  font-family: 'PoppinsBold';
  src: url('../fonts/Poppins-Bold.ttf');
}

.font-poppins-bold {
  font-family: 'PoppinsBold', sans-serif;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.App {
  background-image: url(../images/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

:root {
  --onboard-modal-z-index: 9999;
  /* --onboard-wallet-columns: 1; */
}